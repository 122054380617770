import React, { useMemo, useState } from "react";
import TextTransition, { presets } from "react-text-transition";
import "@fontsource/roboto";
import "./App.css";
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { useEffect } from "react";

const useStyles = makeStyles({
  hideOverflow: {
    overflow: "hidden",
  },
});

/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 */
function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function App() {
  const [rolling, setRolling] = useState(false);
  const [TimeoutID, setTimeoutID] = useState<NodeJS.Timeout>();
  const [currentRoll, setCurrentRoll] = useState<number>();

  const [namaPeserta, setNamaPeserta] = useState("");
  const [namaPesertaError, setNamaPesertaError] = useState(false);

  const [perTeams, setPerTeams] = useState(2);
  const [themes, setThemes] = useState<string>("");
  const [themesError, setThemesError] = useState(false);
  const [themeResults, setThemeResults] = useState<[string, string][]>([]);

  const parsedThemes = useMemo(() => themes.trim().split("\n"), [themes]);

  // parse themes and check for errors
  useEffect(() => {
    if (
      parsedThemes.filter((theme) => theme.split(".")[1] === undefined).length >
      0
    ) {
      setThemesError(true);
    } else {
      setThemesError(false);
    }
  }, [parsedThemes]);

  const parsedJumlah = useMemo(() => {
    const result: number[] = [];
    themeResults.forEach((data) => {
      const themeNumber = Number(data[1]);
      result[themeNumber] = result[themeNumber] ?? 0;
      result[themeNumber]++;
    });
    return result;
  }, [themeResults]);
  const usableThemes = useMemo(() => {
    const results: number[] = [];
    parsedThemes.forEach((theme, index) => {
      const jumlah = parsedJumlah[Number(theme.split(".")[0])];
      if (jumlah === undefined || jumlah < perTeams) results.push(index);
    });
    return results;
  }, [parsedThemes, parsedJumlah]);

  // fetch data from localstorage
  useEffect(() => {
    const toParse = {
      themeResults: localStorage.getItem("themeResults"),
      perTheme: localStorage.getItem("perTheme"),
      themes: localStorage.getItem("themes"),
    };
    try {
      if (toParse.themeResults) {
        setThemeResults(JSON.parse(toParse.themeResults));
      }
      if (toParse.perTheme) {
        setPerTeams(Number.parseInt(toParse.perTheme));
      }
      if (toParse.themes) {
        setThemes(toParse.themes);
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("perTheme", String(perTeams));
  }, [perTeams]);

  useEffect(() => {
    localStorage.setItem("themeResults", JSON.stringify(themeResults));
  }, [themeResults]);

  const styles = useStyles();

  function roll() {
    // check if it has name
    const currentPeserta = namaPeserta.trim();
    if (currentPeserta === "") {
      return setNamaPesertaError(true);
    }
    setNamaPesertaError(false);

    const newState = !rolling;
    setRolling(newState);
    if (newState) {
      setTimeoutID(
        setInterval(() => {
          setCurrentRoll(
            usableThemes[getRandomInt(0, usableThemes.length - 1)]
          );
        }, 200)
      );
    } else if (TimeoutID !== undefined) {
      clearTimeout(TimeoutID);

      // add to the results
      setThemeResults([
        ...themeResults,
        [
          currentPeserta,
          currentRoll !== undefined
            ? parsedThemes[currentRoll].split(".")[0]
            : "error",
        ],
      ]);

      // clear current name
      setNamaPeserta("");
    }
  }

  return (
    <div className="App">
      <Box p={3}>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant="h3"
                align="center"
                component="h1"
                gutterBottom
              >
                Pengundian Tema Orasi
              </Typography>
            </Grid>
            <Grid container spacing={2} item xs={12}>
              <Grid container spacing={2} item xs={8}>
                <Grid item xs={12}>
                  <TextField
                    label="Nama Peserta"
                    value={namaPeserta}
                    onChange={(e) => setNamaPeserta(e.currentTarget.value)}
                    variant="outlined"
                    fullWidth
                    required
                    size="small"
                    error={namaPesertaError}
                  />
                </Grid>
                <Grid container item xs={12}>
                  <Button
                    onClick={roll}
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    {rolling ? "Stop" : "Roll"}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Paper variant="outlined" className={styles.hideOverflow}>
                    <Box p={2} pt={8} pb={8}>
                      <Typography variant="h3">
                        <TextTransition
                          text={
                            currentRoll !== undefined
                              ? parsedThemes[currentRoll] ?? ""
                              : ""
                          }
                          springConfig={presets.stiff}
                        />
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>Peserta</TableCell>
                        <TableCell align="right">Undian</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {themeResults.map((data, index) => (
                        <TableRow key={data[0]}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{data[0]}</TableCell>
                          <TableCell align="right">{data[1]}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid container spacing={2} item xs={12}>
              <Grid container item xs={8}>
                <TextField
                  label="Daftar Tema"
                  value={themes}
                  onBlur={(e) => localStorage.setItem("themes", themes)}
                  error={themesError}
                  onChange={(e) => setThemes(e.currentTarget.value)}
                  multiline
                  rows={10}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid container item xs={4}>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Tema</TableCell>
                        <TableCell align="right">Jumlah</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {parsedJumlah.map((data, index) =>
                        data !== 0 ? (
                          <TableRow key={index}>
                            <TableCell>{index}</TableCell>
                            <TableCell align="right">{data}</TableCell>
                          </TableRow>
                        ) : null
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid container item xs={8}>
                <TextField
                  label="Peserta Per Tema"
                  size="small"
                  value={perTeams}
                  onChange={(e) => setPerTeams(Number(e.currentTarget.value))}
                  variant="outlined"
                  type="number"
                  inputProps={{
                    min: 1,
                    max: 10,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid
                container
                spacing={2}
                item
                xs={4}
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item>
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={(e) => setThemeResults([])}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
}

export default App;
